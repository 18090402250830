/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateUserRequest } from "../models/ActivateUserRequest";
import type { ActivateUserResponse } from "../models/ActivateUserResponse";
import type { CustomerUserInviteRequest } from "../models/CustomerUserInviteRequest";
import type { CustomerUserInviteResponse } from "../models/CustomerUserInviteResponse";
import type { User } from "../models/User";
import type { UserCreateRequest } from "../models/UserCreateRequest";
import type { UserOptions } from "../models/UserOptions";
import type { UserRolesUpdateRequest } from "../models/UserRolesUpdateRequest";
import type { UserRolesUpdateResponse } from "../models/UserRolesUpdateResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UserService {
  /**
   * Activate a user's account.
   * Accepts an invite token from a new user and creates & activates their account.
   * @param requestBody
   * @returns ActivateUserResponse OK
   * @throws ApiError
   */
  public static activateUser(
    requestBody: ActivateUserRequest
  ): CancelablePromise<ActivateUserResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/activate",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        409: `User is already active for this customer`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Get my user details
   * Get user details for authenticated user
   * @returns User OK
   * @throws ApiError
   */
  public static getUserMe(): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/me",
      errors: {
        401: `Unauthorized`,
        404: `User doesn't exist for you`,
      },
    });
  }

  /**
   * Create a new user for myself
   * Create a user for the currently authenticated user
   * @param requestBody
   * @returns User OK
   * @throws ApiError
   */
  public static createUserMe(
    requestBody: UserCreateRequest
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/me",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        409: `User already exists`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Update my options
   * Updates options for currently authenticated user
   * @param requestBody
   * @returns UserOptions OK
   * @throws ApiError
   */
  public static updateUserMeOptions(
    requestBody: UserOptions
  ): CancelablePromise<UserOptions> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/user/me/options",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        404: `User doesn't exist for you`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Invite a user to a customer
   * Creates and sends an invite token to the prospective user, on signup the user will be added to the specified customer.
   * @param requestBody
   * @returns CustomerUserInviteResponse OK
   * @throws ApiError
   */
  public static inviteUserToCustomer(
    requestBody: CustomerUserInviteRequest
  ): CancelablePromise<CustomerUserInviteResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/user/invite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        409: `User already invited`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Validate and return a user's invitation to customer
   * Validates the invite token and the encoded information in itand returns the invite information if valid
   * @param token
   * @returns CustomerUserInviteResponse OK
   * @throws ApiError
   */
  public static getCustomerUserInvite(
    token: string
  ): CancelablePromise<CustomerUserInviteResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/user/invite",
      query: {
        token: token,
      },
      errors: {
        400: `Missing 'token' from request`,
        401: `Unauthorized`,
        404: `Invite not found`,
        422: `ValidationErrorResponse`,
      },
    });
  }

  /**
   * Update roles for a user
   * Updates roles for a user based on the user id and request body replacing the existing roles for the user
   * @param userId
   * @param requestBody
   * @returns UserRolesUpdateResponse OK
   * @throws ApiError
   */
  public static updateRolesForUser(
    userId: string,
    requestBody: UserRolesUpdateRequest
  ): CancelablePromise<UserRolesUpdateResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/user/{userId}/roles",
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `ValidationErrorResponse`,
      },
    });
  }
}
