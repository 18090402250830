/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CustomerSource {
  MIGRATED_FROM_CONNECT = "migrated-from-connect",
}
