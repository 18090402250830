<script lang="ts" setup>
import TwoCheckbox from "@two-components/components/TwoCheckbox.vue";
import { trackEvent } from "@two-ui/mixpanel";
import { computed, ref, watch } from "vue";
import { getTimezone } from "@two-ui/util/l10n";
import { formatDateTime } from "@two-ui/util/date";
import { useGlobalStore } from "@two-ui/global-store";

trackEvent("User Settings: Viewed page");

const globalStore = useGlobalStore();
const useUTC = ref(globalStore.timezoneOverride === "Etc/UTC");
const currentTimezone = ref(getTimezone());
const currentTimezoneCity = computed(() => currentTimezone.value.split("/")[1]);

const timezoneSettingChanged = (isChecked: boolean) => {
  trackEvent("User Settings: Toggled UTC", { isChecked });
  if (isChecked) {
    globalStore.setTimezoneOverride("Etc/UTC");
  } else {
    globalStore.clearTimezoneOverride();
  }
  currentTimezone.value = getTimezone();
};
</script>

<template>
  <main class="max-w-screen-lg">
    <div class="font-f37lineca flex items-center justify-between pt-5">
      <h1 class="my-6">User Settings</h1>
    </div>
    <TwoCheckbox
      v-model="useUTC"
      @change="timezoneSettingChanged"
      class="text-base"
      >Use UTC for dates and times</TwoCheckbox
    >
    <div class="mb-[0.2rem] ml-[2rem] mt-[1rem] text-base">
      <p v-if="currentTimezone === 'Etc/UTC'">
        Times will be shown in <b>UTC</b>.<sup>&dagger;</sup>
      </p>
      <p v-else>
        Times will be shown in your local timezone,
        <b>{{ currentTimezoneCity }}</b
        >.<sup>&dagger;</sup>
      </p>
    </div>
    <div class="ml-[2rem] rounded bg-grey-50 px-4 py-3">
      <div class="flex-lg">
        <div>
          <p class="text-center text-sm">
            The date and time will be displayed as follows
          </p>
          <p class="font-f37lineca mt-1 text-center text-2xl">
            <b>{{ formatDateTime(new Date().toISOString()) }}</b
            ><sup>&ddagger;</sup>
          </p>
        </div>
      </div>
    </div>
    <div class="ml-[2rem] text-sm">
      <p class="mt-[1rem]">
        <sup>&dagger;</sup> The timezone in Billing and Finance will always be
        displayed in UTC, regardless of the setting here.
      </p>
      <p class="mt-[1rem]">
        <sup>&ddagger;</sup> There are some cases where the date and time might
        have a different format from that shown here. This is due to technical
        limitations.
      </p>
    </div>
  </main>
</template>

<style>
.font-f37lineca {
  font-family: F37 Lineca;
  font-weight: 850;
}
</style>
