import { reactive, readonly } from "@vue/reactivity";
import { User as Auth0User } from "@auth0/auth0-spa-js";
import { captureMessage } from "./sentry";
import { getDomainFromEmail } from "./util/string";
import {
  DiscountConfig,
  CustomerOrderLimit,
  CustomerFeatures,
  CustomerSource,
} from "../../../open-api/cms";
import { useGlobalStore } from "./global-store";
import { watch } from "vue";
import { FeatureFlaggedCurrencies } from "./constants";
import { isAccountFeatureToggle } from "./util/isAccountFeatureToggle";
import jwtDecode from "jwt-decode";

// TODO: Remove after full migration to v4 payments API
const USE_PAYMENTS_V4_DEFAULT =
  import.meta.env.VITE_USE_PAYMENTS_V4_DEFAULT === "true";

const FeatureToggleDefaultState = {
  debugViews: false,
  designViews: false,
  staffViews: true,
  customization: true,
  orderHistoryFilters: true,
  enableTransactionCsvDownload: true,
  enableConsentBanner: true,
  // TODO: Remove after full migration to v4 payments API
  usePaymentsV4: USE_PAYMENTS_V4_DEFAULT,
  enableTopUpInvoices: true,
  userSettings: true,
  templatesEditing: false,
};

const CurrencyTogglesDefaultState: Record<FeatureFlaggedCurrencies, boolean> = {
  AED: false,
  ARS: true,
  BGN: true,
  BRL: true,
  CLP: true,
  COP: true,
  EGP: false,
  IDR: false,
  ILS: false,
  INR: false,
  MYR: true,
  PEN: true,
  PHP: false,
  SAR: false,
  THB: false,
  TRY: false,
  VND: false,
};

export type FeatureToggles = typeof FeatureToggleDefaultState;
interface AppState {
  customerId: string | null;
  customerName: string | null;
  customerErr: string | null;
  customerDiscountConfig: DiscountConfig | null;
  customerDefaultCurrency: string;
  orderLimits: [] | Array<CustomerOrderLimit> | null;
  isUserStaff: boolean | null;
  userEmail: string | null;
  userPropertiesPopulated: boolean;
  isEmailVerified: boolean | null;
  featureToggles: FeatureToggles;
  customerFeatureFlags: { floats: boolean; cardFees: boolean; fx: boolean };
  useBalancesTncAccepted: boolean | null;
  dataProcessingTncAccepted: boolean | null;
  contactEmailAddress: string | null;
  brandApprovalAccessToken: string | null;
  hiddenNewTags: Array<string>;
  isFirstTimeUser: boolean | null;
  customerSource: CustomerSource | null;
  userPermissions: Array<string> | null;
  currencyToggles: Record<FeatureFlaggedCurrencies, boolean>;
}

const state = reactive<AppState>({
  customerId: null,
  customerName: null,
  customerErr: null,
  customerDiscountConfig: null,
  orderLimits: null,
  customerDefaultCurrency: "$",
  isUserStaff: false,
  userEmail: null,
  userPropertiesPopulated: false,
  isEmailVerified: null,
  useBalancesTncAccepted: null,
  dataProcessingTncAccepted: null,
  featureToggles: { ...FeatureToggleDefaultState },
  customerFeatureFlags: { floats: false, cardFees: false, fx: true },
  contactEmailAddress: null,
  brandApprovalAccessToken: null,
  hiddenNewTags: [],
  isFirstTimeUser: null,
  customerSource: null,
  userPermissions: null,
  currencyToggles: CurrencyTogglesDefaultState,
});

const store = {
  state: readonly(state),

  populateInitialState() {
    this.loadFromStorage();
  },

  setStateFromAuth0User(user: Auth0User) {
    if (user.email_verified !== undefined) {
      state.isEmailVerified = user.email_verified;
    } else {
      captureMessage("Auth0 email_verified is undefined");
    }
    state.userEmail = user.email || null;
  },

  setStatePermissionsFromAuth0JwtToken(token: string) {
    const decodedToken: any = jwtDecode(token);
    state.userPermissions = decodedToken.permissions || [];
    useGlobalStore().userPermissions = decodedToken.permissions || [];
  },

  setUserPermissions(permissions: Array<string>) {
    state.userPermissions = permissions;
    useGlobalStore().userPermissions = permissions;
  },

  setIsStaff(isStaff: boolean) {
    state.isUserStaff = isStaff;
  },

  setDataProcessingTncAccepted(termsAccepted: boolean | null) {
    state.dataProcessingTncAccepted = termsAccepted;
  },

  setUseBalancesTnCAccepted(termsAccepted: boolean) {
    state.useBalancesTncAccepted = termsAccepted;
    useGlobalStore().useBalancesTncAccepted = termsAccepted;
  },

  getDataProcessingTncAccepted() {
    return state.dataProcessingTncAccepted;
  },

  getUseBalancesTnCAccepted() {
    return state.useBalancesTncAccepted;
  },

  setUserPropertiesPopulated(populated: boolean) {
    state.userPropertiesPopulated = populated;
  },

  clearIsStaff() {
    state.isUserStaff = false;
  },

  setBrandApprovalAccessToken(token: string) {
    state.brandApprovalAccessToken = token;
    useGlobalStore().brandApprovalAccessToken = token;
  },

  getBrandApprovalAccessToken() {
    return state.brandApprovalAccessToken;
  },
  loadFromStorage() {
    state.customerId = localStorage.getItem("customerId") || null;
    useGlobalStore().customerId = state.customerId;
    state.customerName = localStorage.getItem("customerName") || null;
    state.customerDefaultCurrency =
      localStorage.getItem("customerDefaultCurrency") || "$";
    state.hiddenNewTags = JSON.parse(
      localStorage.getItem("hiddenNewTags") || "[]"
    );
    state.orderLimits = JSON.parse(
      localStorage.getItem("customerOrderLimits") || "[]"
    );
    state.customerSource = localStorage.getItem(
      "customerSource"
    ) as CustomerSource;

    const customerDiscountConfig = localStorage.getItem(
      "customerDiscountConfig"
    );
    if (customerDiscountConfig) {
      try {
        state.customerDiscountConfig = JSON.parse(customerDiscountConfig);
      } catch (ex) {
        state.customerDiscountConfig = null;
      }
    }
    state.customerFeatureFlags = JSON.parse(
      localStorage.getItem("customerFeatureFlags") || "{}"
    );
    useGlobalStore().customerFeatureFlags = state.customerFeatureFlags;
    // Load feature toggles
    (Object.keys(state.featureToggles) as Array<keyof FeatureToggles>).forEach(
      (key) => {
        const toggleInLocalStorage = localStorage.getItem(
          `featureToggles.${key}`
        );
        // Result will be 'null' if unset or a stringy true/false
        if (toggleInLocalStorage !== null)
          state.featureToggles[key as keyof FeatureToggles] =
            toggleInLocalStorage === "true";

        if (isAccountFeatureToggle(key)) {
          useGlobalStore()[key] = state.featureToggles[key];
        }
      }
    );
    // Assign global value to global store
    useGlobalStore().customerDefaultCurrency = state.customerDefaultCurrency;
    // Load first time user, default to true if not set
    state.isFirstTimeUser = !(
      localStorage.getItem("isFirstTimeUser") === "false"
    );

    const currencyToggles = localStorage.getItem("currencyToggles");
    if (currencyToggles) {
      try {
        state.currencyToggles = JSON.parse(currencyToggles);
        useGlobalStore().currencyToggles = JSON.parse(currencyToggles);
      } catch (e) {
        state.currencyToggles = CurrencyTogglesDefaultState;
        useGlobalStore().currencyToggles = CurrencyTogglesDefaultState;
        localStorage.setItem(
          "currencyToggles",
          JSON.stringify(CurrencyTogglesDefaultState)
        );
      }
    } else {
      useGlobalStore().currencyToggles = CurrencyTogglesDefaultState;
    }

    return state;
  },

  hideNewTag(tagIdentifier: string) {
    state.hiddenNewTags.push(tagIdentifier);
    localStorage.setItem("hiddenNewTags", JSON.stringify(state.hiddenNewTags));
  },
  shouldShowNewTag(app: string) {
    return !state.hiddenNewTags.includes(app);
  },
  isFloatsFeatureEnabledForCustomer() {
    return state.customerFeatureFlags.floats;
  },
  isCardFeesFeatureEnabledForCustomer() {
    return state.customerFeatureFlags.cardFees;
  },
  setFeatureToggle(key: keyof FeatureToggles, value: boolean) {
    state.featureToggles[key] = value;
    localStorage.setItem(`featureToggles.${key}`, value.toString());
  },
  getFeatureToggle(feature: keyof FeatureToggles) {
    return store.state.featureToggles[feature];
  },
  setCurrencyToggles(
    currencyToggles: Record<FeatureFlaggedCurrencies, boolean>
  ) {
    state.currencyToggles = currencyToggles;
    useGlobalStore().currencyToggles = currencyToggles;
    localStorage.setItem("currencyToggles", JSON.stringify(currencyToggles));
  },
  resetAllFeatureTogglesToDefault() {
    Object.entries(FeatureToggleDefaultState).forEach(([key, value]) => {
      this.setFeatureToggle(key as keyof FeatureToggles, value);
      console.log(key, value);
    });
  },
  setOrderLimits(limits: Array<CustomerOrderLimit> | null) {
    state.orderLimits = limits;
    localStorage.setItem("customerOrderLimits", JSON.stringify(limits));
  },
  getOrderLimits() {
    return state.orderLimits;
  },
  getCustomerId() {
    return state.customerId;
  },
  setCustomer(
    id: string,
    name: string,
    contactEmailAddress: string,
    customerFeatures: CustomerFeatures,
    customerSource?: CustomerSource
  ) {
    state.customerId = id;
    state.customerName = name;
    state.contactEmailAddress = contactEmailAddress;
    state.customerSource = customerSource || null;
    useGlobalStore().customerSource = state.customerSource;
    useGlobalStore().customerName = name;
    useGlobalStore().customerId = id;
    useGlobalStore().customerContactEmail = contactEmailAddress;
    localStorage.setItem("customerId", state.customerId);
    localStorage.setItem("customerName", state.customerName);
    this.populateCustomerFeatureFlags(customerFeatures);
  },

  setCustomerDiscountConfig(config: DiscountConfig | null) {
    state.customerDiscountConfig = config;
    localStorage.setItem(
      "customerDiscountConfig",
      JSON.stringify(state.customerDiscountConfig)
    );
  },

  setCustomerDefaultCurrency(currency: string) {
    state.customerDefaultCurrency = currency;
    useGlobalStore().customerDefaultCurrency = currency;
    localStorage.setItem(
      "customerDefaultCurrency",
      state.customerDefaultCurrency
    );
  },

  populateCustomerFeatureFlags(customerFeatures: CustomerFeatures) {
    // balances are not available for all customers, hence default state is false
    state.customerFeatureFlags.floats = customerFeatures.balances || false;
    // card fees are paid only by customers who have access to balances
    state.customerFeatureFlags.cardFees = customerFeatures.balances || false;
    // FX access is available for all customers but can be set as false from the CMS
    state.customerFeatureFlags.fx = customerFeatures.fx && true;

    useGlobalStore().customerFeatureFlags = {
      floats: state.customerFeatureFlags.floats,
      cardFees: state.customerFeatureFlags.cardFees,
      fx: state.customerFeatureFlags.fx,
    };

    localStorage.setItem(
      "customerFeatureFlags",
      JSON.stringify(state.customerFeatureFlags)
    );
  },

  unmarkFirstTimeUser() {
    state.isFirstTimeUser = false;
    localStorage.setItem("isFirstTimeUser", "false");
  },

  waitForUserPermissionsToPopulate() {
    return new Promise((resolve) => {
      if (state.userPermissions) resolve(true);
      watch(
        () => state.userPermissions,
        (populated) => {
          if (populated) resolve(true);
        }
      );
    });
  },

  waitForUserPropertiesToPopulate() {
    return new Promise((resolve) => {
      if (state.userPropertiesPopulated) resolve(true);
      watch(
        () => state.userPropertiesPopulated,
        (populated) => {
          if (populated) resolve(true);
        }
      );
    });
  },

  getCustomerDiscount() {
    if (state.customerDiscountConfig == null) {
      return 0;
    } else {
      return 1 - state.customerDiscountConfig.flatRateMultiplier;
    }
  },

  getCustomerDiscountConfig() {
    return state.customerDiscountConfig;
  },

  clearCustomer() {
    state.customerId = null;
    state.customerName = null;
    state.customerDiscountConfig = null;
    state.orderLimits = [];
    localStorage.removeItem("customerId");
    localStorage.removeItem("customerName");
    localStorage.removeItem("customerDiscountConfig");
  },

  clearUserPermissions() {
    state.userPermissions = [];
  },

  setCustomerErr(err: string) {
    state.customerErr = err;
  },

  clearCustomerErr() {
    state.customerErr = null;
  },

  getCurrentUserEmailDomain() {
    if (state.userEmail) {
      return getDomainFromEmail(state.userEmail);
    }
    return "";
  },
};

export default store;
