<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvalidTokenError } from "jwt-decode";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { useRoute } from "vue-router";
import { doLogin } from "@two-ui/auth0";
import {
  saveInviteToken,
  decodeInviteToken,
} from "@two-ui/services/invite-token";
import { assert } from "@two-ui/util/typescript";
import GenericError from "@two-ui/components/GenericError.vue";
const {
  query: { token: inviteToken },
} = useRoute();
const error = ref("");

const parseToken = (token: unknown) => {
  if (typeof token !== "string") {
    error.value =
      "The token is missing from your invite link, please try clicking the link again.";
    return;
  }
  try {
    return decodeInviteToken(token);
  } catch (e) {
    if (e instanceof InvalidTokenError) {
      error.value =
        "The token is invalid in your invite link, please try clicking the link again.";
      return;
    }
    throw e;
  }
};

const parsedToken = parseToken(inviteToken);
if (parsedToken) {
  // Needs checking again, but in reality parsedToken will be null if the token is invalid.
  assert(typeof inviteToken === "string", "Invite token invalid");
  saveInviteToken(inviteToken);
  onMounted(() => {
    // redirect to auth0 signup, passing email from the token.
    doLogin({ screen_hint: "signup", login_hint: parsedToken.email });
  });
}
</script>

<template>
  <FullPageLoader v-if="!error">Please wait…</FullPageLoader>
  <GenericError v-else>
    <template v-slot:title>There was a problem with your invite</template>
    <template v-slot:message
      ><p>{{ error }}</p></template
    >
  </GenericError>
</template>
