/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OnboardingTaskStateEnum {
  STARTED = "STARTED",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}
