import jwtDecode, { InvalidTokenError } from "jwt-decode";

const inviteTokenLocalStorageKey = "inviteToken";

/**
 * save invite token in local storage
 * @param token
 */
export function saveInviteToken(token: string) {
  localStorage.setItem(inviteTokenLocalStorageKey, token);
}

/**
 * check if invite token exists in local storage
 * @param token
 */
export function inviteTokenExists(): boolean {
  return !!localStorage.getItem(inviteTokenLocalStorageKey);
}

/**
 * get invite token from local storage
 * @param token
 */
export function getInviteToken(): string | null {
  return localStorage.getItem(inviteTokenLocalStorageKey);
}

/**
 * remove invite token key from local storage
 */
export function removeInviteToken() {
  localStorage.removeItem(inviteTokenLocalStorageKey);
}

export interface InviteTokenData {
  email: string;
  customer_id: string;
  role_name: string;
  iat: number;
  exp: number;
}

function instanceOfInviteTokenData(object: unknown): object is InviteTokenData {
  return (
    object instanceof Object &&
    "email" in object &&
    "customer_id" in object &&
    "role_name" in object &&
    "iat" in object &&
    "exp" in object
  );
}

// Decode an invite token to get data, such as email address.
// This DOES NOT verify the authenticity of the token, this MUST happen on the server.
// This function will throw an InvalidTokenError if the token is malformed.
export function decodeInviteToken(token: string): InviteTokenData {
  const decodedToken = jwtDecode(token);
  if (!instanceOfInviteTokenData(decodedToken)) {
    throw new InvalidTokenError("Token data doesn't match expected format");
  }
  return decodedToken;
}
